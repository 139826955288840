import * as React from "react";
import Layout from "@src/components/templates/layout";
import Seo from "@src/components/seo";
import Innovation from "@src/components/pages/innovation";

const InnovationPage = () => {
    return(
        <Layout>
            <Seo title="革命事業部" />
            <Innovation />
        </Layout>
    )
}

export default InnovationPage