import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./style.css"

const BaseDivisionService = ({children}) => {
    return(
    <section className="page-section page-section-background-secondary division-service">
        <div className="l-contents-padding division-service-wrap">{children}</div>
    </section>
    )
}

/**
 * 革命事業部が提供しているサービス一覧セクション
 */
export const InnovationDivisionService = () => {
    return(
        <BaseDivisionService>
            <div className="division-service-inner">
                <a className="division-service-link" href="https://www.mainstorage.jp/" target="_blank" rel="noreferrer">
                    <StaticImage src="../../../images/innovation/メインストレージ.png" alt="メインストレージ" />
                </a>
                <p className="division-service-description">高信頼・高耐久・高可用性のクラウドストレージが安全なクラウドへの移行を可能にします。</p>
            </div>
            <div className="division-service-inner">
                <a className="division-service-link" href="https://www.main-line.jp/" target="_blank" rel="noreferrer">
                    <StaticImage src="../../../images/innovation/LINE予約.png" alt="かんたん LINE予約" />
                </a>
                <p className="division-service-description">LINEでかんたん! WEB予約がお手頃価格で!</p>
            </div>
        </BaseDivisionService>
    )
}