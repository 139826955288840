import * as React from "react";

import Accordion from "@src/components/atoms/accordion/index"
import ContactInfo from "@src/components/molecules/contact-info/index";
import Divisions from "@src/components/molecules/divisions/index";
import MainStage from "@src/components/molecules/main-stage/index";
import PageHeader from "@src/components/molecules/page-header/index";
import Works from "@src/components/molecules/works/index";

import { InnovationDivisionService } from "@src/components/molecules/division-service/index";
import { Up } from "@src/components/molecules/fadein/index"
import { InnovationScrollDescription } from "@src/components/molecules/page-scroll-description/index";


const Innovation = () => {
    /**
    * @type {React.MutableRefObject<HTMLVideoElement>}
    */
    const videoRef = React.useRef(null)

    React.useEffect(() => {
        const timer = setInterval(() => {
            videoRef.current.pause();
            const duration = videoRef.current.duration;
            if (isNaN(duration)) return
            if (window.scrollY + window.innerHeight < window.innerHeight * 2.5) {
                videoRef.current.currentTime = (videoRef.current.duration * window.scrollY) / (window.innerHeight * 2.5 - window.innerHeight);
                videoRef.current.style.position = "fixed";
                videoRef.current.style.top = "61px"
            } else {
                videoRef.current.currentTime = 0;
                videoRef.current.style.position = "sticky";
                videoRef.current.style.top = "61px";
            }
        }, 100)

        return () => {
            clearInterval(timer)
        }
    }, [])

    return(
        <article className="innovation">
            <PageHeader title="革命事業部">
                <InnovationScrollDescription />
                <video src="https://dl5km2ob7ccxv.cloudfront.net/main/glow-cubes.mp4" autoPlay muted playsInline style={{ width: "100%"}} ref={videoRef}>
                    <p>動画の再生に対応していません</p>
                </video>
            </PageHeader>

            <section className="page-section">
                <div className="l-contents-padding">
                    <Up>
                        <h3 className="page-section-title">Service</h3>
                    </Up>
                        <div className="accordion-wrap">
                            <Up>
                                <Accordion title="WEBデザイン" id="web-design">
                                    <li className="accordion-item">ホームページ制作</li>
                                    <li className="accordion-item">ECサイト制作</li>
                                </Accordion>
                            </Up>
                            <Up>
                                <Accordion title="システム開発" id="application">
                                    <li className="accordion-item">アプリ制作・クラウド化</li>
                                    <li className="accordion-item">業務効率システム制作</li>
                                    <li className="accordion-item">人工知能システム制作(AI)</li>
                                </Accordion>
                            </Up>
                        </div>
                </div>
            </section>

            <Works />
            <InnovationDivisionService />
            <MainStage />
            <Divisions page="innovation" />
            <ContactInfo />
        </article>
    )
}

export default Innovation